import axiosWrapper from "../utils/axiosWrapper";
import {createRandomBuf, createState, bufToHexString, hashBufAsStr } from "../utils/functions/customcrypto";

const baseUrl = import.meta.env.VITE_AUTH_URL;

const {runStatelessPost, runPost, runIFrame} = axiosWrapper.useAxiosState(baseUrl);

const client_id = import.meta.env.VITE_CLIENT_ID;
const redirect_uri = import.meta.env.VITE_THIS_BASE_URL;

let code_verif;

//first step in auth flow, inital redir
const initialAuth = async (iFrameRef) => {
    const code_verifier_buf = createRandomBuf(14);
    code_verif = bufToHexString(code_verifier_buf);
    runIFrame(iFrameRef,{
        code_challenge:await hashBufAsStr(code_verifier_buf),
        client_id,
        redirect_uri,
        code_challenge_method:"S256",
        response_type:"code"
    }, "auth")

    // 
    
    // 
    // window.sessionStorage.setItem("code_verif", code_verif_hex_str);

    // try{
    //     const code_challenge = ;
    //     //console.log(formRef)
    //     formRef.querySelector("input[name='code_challenge']").value = code_challenge;
    //     formRef.querySelector("input[name='client_id']").value = client_id;
    //     formRef.querySelector("input[name='redirect_uri']").value = redirect_uri;
        
    //     runFormPost(formRef);
    // } catch(err) {
    //     console.log(err)
    //     throw {message:"could not perform login"};
    // }
};

// final step in auth flow, after redir
const createToken = async (code) => {
    if(!code_verif){
        throw {message:"could not perform login."};
    }
    window.sessionStorage.removeItem("code_verif");
    try{
        const token_resp = await runPost("token", {
            code_verifier:code_verif,
            code,
            grant_type: "authorization_code",
            client_id
        });
        window.localStorage.setItem("refresh_token", token_resp.refresh_token);
        //console.log(window.localStorage)
        //console.log(window.localStorage.getItem("refresh_token"))
        window.location.replace(`/app?auth_token=${token_resp.auth_token}`);
    } catch(err) {
        throw {message: "could not perform login"}
    }
};

/* TODO: this should handle the 'rem' claim */
 
// no redir unless no refresh token
const refreshPage = async (iFrameRef) => {
    const refresh_token = window.localStorage.getItem("refresh_token");
    if(!refresh_token){
        return initialAuth(iFrameRef);
    }
    try{
        const refresh_resp = await runStatelessPost("token/refresh", {
            refresh_token
        });
        window.localStorage.setItem("refresh_token", refresh_resp.refresh_token);
        window.location.replace(`/app?auth_token=${refresh_resp.auth_token}`)
    } catch(err) {
        window.localStorage.removeItem("refresh_token");
        window.location.reload();
    }
    
};



const authService = {
    createToken,
    refreshPage
};

export default authService;
